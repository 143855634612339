html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  height: 100%;

  background-image: url(/static/media/checkedFlag3.ad0d1f30.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}



/* PAPER-LIST */
.paper-list-container {
  margin: 0.5em;
}

.paper-list-header {
  font-weight: bold;
  height: 1.5em;
  margin-bottom: 1em !important;
}

.paper-list-container .paper {
  padding: 0.5em;
  margin-bottom: 0.5em;
}

.MuiPaper-root-98 {
  background-color: rgba(255, 255, 255, 0.95) !important;
}



/* CANVAS */
.lap-timer-canvas {
  width: 100%;
  height: 100%;
}



/* LINKS */
a.app-link:link, a.app-link:visited, a.app-link:hover, a.app-link:active {
  text-decoration: none;
  color: rgb(63, 81, 181);
}

a.app-link:hover {
  cursor: pointer;
}

a.app-link:hover > div {
  background-color: #DEDEDE !important;
  color: #00147d;
}



/* LONG CONTENT */
.long-content h2 {
  font-size: 1.2em;
  font-weight: bold;
}

.long-content p {
  text-align: justify;
}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.track-selector-row .track-selector-row-item {
  text-align: left;
}

.track-selector-row .track-selector-row-item:last-child {
  padding-left: 4%;
}

.track-selector-row-item-info {
  text-align: right;
}

svg.magicIcon {
  font-size: 0.8em;
}
.lap-timer-back-button-paper {
  position: absolute;
  
  bottom: 0.5em;
  left: 0.5em;

  width: 3.5em;
  height: 1.5em;

  padding: 8px;
}

.lap-timer-times-list-button-paper {
  position: absolute;
  
  bottom: 0.5em;
  right: 0.5em;

  width: 3.5em;
  height: 1.5em;

  padding: 8px;
}
.track-info-row-header {
  text-align: right;
  font-weight: bold;
}

.track-info-row-content {
  text-align: left;
}
