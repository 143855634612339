html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  height: 100%;

  background-image: url("img/checkedFlag3.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}



/* PAPER-LIST */
.paper-list-container {
  margin: 0.5em;
}

.paper-list-header {
  font-weight: bold;
  height: 1.5em;
  margin-bottom: 1em !important;
}

.paper-list-container .paper {
  padding: 0.5em;
  margin-bottom: 0.5em;
}

.MuiPaper-root-98 {
  background-color: rgba(255, 255, 255, 0.95) !important;
}



/* CANVAS */
.lap-timer-canvas {
  width: 100%;
  height: 100%;
}



/* LINKS */
a.app-link:link, a.app-link:visited, a.app-link:hover, a.app-link:active {
  text-decoration: none;
  color: rgb(63, 81, 181);
}

a.app-link:hover {
  cursor: pointer;
}

a.app-link:hover > div {
  background-color: #DEDEDE !important;
  color: #00147d;
}



/* LONG CONTENT */
.long-content h2 {
  font-size: 1.2em;
  font-weight: bold;
}

.long-content p {
  text-align: justify;
}