.lap-timer-back-button-paper {
  position: absolute;
  
  bottom: 0.5em;
  left: 0.5em;

  width: 3.5em;
  height: 1.5em;

  padding: 8px;
}

.lap-timer-times-list-button-paper {
  position: absolute;
  
  bottom: 0.5em;
  right: 0.5em;

  width: 3.5em;
  height: 1.5em;

  padding: 8px;
}