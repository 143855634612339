.track-selector-row .track-selector-row-item {
  text-align: left;
}

.track-selector-row .track-selector-row-item:last-child {
  padding-left: 4%;
}

.track-selector-row-item-info {
  text-align: right;
}

svg.magicIcon {
  font-size: 0.8em;
}